<template>
  <div class="imageBackground">
    <div class="contentContainer">
      <custom-header />
      <div class="mainContainer">
        <div class="titleContainer bg-blue">
          <p class="titleText">{{ $t("labels.noteOfTheDay") }}</p>
        </div>
        <p class="subTitleText">
          {{ getFormattedDate() }}
        </p>
        <div
          class="contentContainer"
          v-if="dailyNote != null && dailyNote.length == 2"
        >
          <p class="topicText">{{ dailyNote[1].title }}</p>
          <div class="topic1ImageContainer">
            <img class="topicImage" :src="'data:image/png;base64,' + dailyNote[1].image" />
          </div>
          <p class="desc">{{ dailyNote[1].text }}</p>
          <div class="divider"></div>
          <p class="topicText mt-30">{{ dailyNote[0].title }}</p>
          <div class="rowContainer">
            <div class="topic2ImageContainer">
              <img :src="'data:image/png;base64,' + dailyNote[0].image" class="topicImage" />
            </div>
            <p class="desc m-0 hp-100">{{ dailyNote[0].text }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CustomHeader from "../components/CustomHeader.vue";
import moment from "moment";
import "moment/locale/es";
import { mapState } from "vuex";
moment.locale("es");
export default {
  name: "DayNotePage",

  components: {
    CustomHeader,
  },

  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    goToLogin() {
      this.$router.push("/login");
    },
      
      getFormattedDate() {
        return moment(new Date()).format("dddd DD MMMM");
      },
    },

    computed: mapState({
      dailyNote: (state) => state.main.dailyNote,
    }),

    mounted() {
      this.$store.dispatch("main/getDailyNoteAttempt");
    },
  };
  </script>

  <style scoped>
  .subTitleText {
    color: var(--textColor);
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
  }
  .topicText {
    font-size: 18px;
    text-align: center;
    color: black;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .desc {
    background-color: rgb(241, 242, 242);
    font-size: 16px;
    color: var(--textColor);
    padding: 10px;
    margin: 10px;
    margin-bottom: 0px;
    flex: 1;
  }
  .divider {
    margin-top: 30px;
    height: 1px;
    background-color: lightgray;
    width: 100%;
  }
  .topic1ImageContainer {
    width: 80%;
    padding-top: 45%;
    position: relative;
    overflow: hidden;
  }
  .topicImage {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    height: 100%;
  }
  .rowContainer {
    display: flex;
    background-color: rgb(241, 242, 242);
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .topic2ImageContainer {
    width: 25%;
    padding-top: 25%;
    position: relative;
    overflow: hidden;
  }
  .m-0 {
    margin: 0px;
  }
  .hp-100 {
    height: 100%;
    flex: 1;
  }
</style>